import React from 'react';
import ReactDOM from 'react-dom';

import '@babel/polyfill';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/ru';

import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { rootReducer, rootSaga } from '././modules/index';

import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import theme from './theme';

import MasterComponent from './modules/master/MasterComponent';

import { create } from 'jss';
import { ThemeProvider, jssPreset, StylesProvider } from '@material-ui/styles';

import 'normalize.css';
import 'react-notifications-component/dist/theme.css';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import 'fontsource-roboto';


const jss = create({
  ...jssPreset(),
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk,sagaMiddleware)));
sagaMiddleware.run(rootSaga);


if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactDOM.render(
  <StylesProvider jss={jss}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MasterComponent />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </StylesProvider>,
  document.getElementById('root')
);

reportWebVitals();
