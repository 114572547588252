import axios from "axios";
import { api } from "./interceptor";


export const getPublicationType = () => {
  return api.get('/api/PublicationType');
}

export const getPublicationTypeForRegistration = () => {
  return axios.get('/api/PublicationType');
}
export const addPublicationType = publicationType => {
  return api.post(`/api/PublicationType`, publicationType);
}

export const removePublicationType = id => {
  return api.delete(`/api/PublicationType/${id}`)
}

export const updatePublicationType = (id, publicationType) => {
  return api.put(`/api/PublicationType/${id}`, publicationType);
}

export const getPublicationTypeById = id => {
  return axios.get(`api/PublicationType/${id}`);
}
