import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditOrderLoadable = Loadable({
  loader: () => import('../containers/container_editOrder'),
  loading: Loading,
})

export const EditOrder = props => <EditOrderLoadable {...props}/>;
