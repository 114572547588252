import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PublisherList } from 'modules/publisher_list/loaders';
import { CreatePublisher } from 'modules/create_publisher/loaders';
import { EditPublisher } from 'modules/edit_publisher/loaders';

export const RoutesPublisher = () => (
  <Switch>
    <Route exact path="/publisher" component={ PublisherList } />
    <Route exact path="/publisher/create" component={ CreatePublisher } />
    <Route exact path="/publisher/edit/:id" component={ EditPublisher } />
  </Switch>
);
