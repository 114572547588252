import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const CreateFeatureLoader = Loadable({
  loader: () => import('../container/container_createFeature'),
  loading: Loading,
});

export const CreateFeature = (props) => <CreateFeatureLoader {...props}/>;
