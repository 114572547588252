import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditQuotationLoadable = Loadable({
  loader: () => import('../containers/container_editQuotation'),
  loading: Loading,
});

export const EditQuotation = props => <EditQuotationLoadable {...props}/>;
