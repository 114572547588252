import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const RegistrationLoadable = Loadable({
  loader: () => import('../containers/registration'),
  loading: Loading,
});

export const Registration = (props) => <RegistrationLoadable { ...props} />;
