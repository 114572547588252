import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const PublicationTypeListLoadable = Loadable({
  loader:() => import('../containers/container_publicationTypeList'),
  loading: Loading,
})

export const PublicationTypeList = (props) => <PublicationTypeListLoadable {...props}/>;
