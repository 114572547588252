import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const CreateQuotationLoader = Loadable({
  loader: () => import('../containers/container_createQuotation'),
  loading: Loading,
});

export const CreateQuotation = (props) => <CreateQuotationLoader {...props}/>;
