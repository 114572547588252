import React from 'react';
import '../input.scss';

const Input = (props) => {
  return (
    <input onChange={(event)=> props.setValue(event.target.value)}
           autoComplete={props.value}
           required={props.value}
           value={props.value}
           type={props.type}
           placeholder={props.placeholder}/>
  );
};

export default Input
