import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const CreateContractLoader = Loadable({
  loader: () => import('../containers/container_createContract'),
  loading: Loading,
});

export const CreateContract = (props) => <CreateContractLoader {...props} />;
