import React from 'react';

import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from 'app';

export const history = createBrowserHistory();

export const RoutesMain = () => (
    <Router history={ history }>
      <Switch>
        <Route path="/" component={ App } />
      </Switch>
    </Router>
);
