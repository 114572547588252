import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const FeatureListLoadable = Loadable( {
  loader: () => import('../containers/container_featureList'),
  loading: Loading,
})

export const FeatureList = (props) => <FeatureListLoadable {...props} />;
