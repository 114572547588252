import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const OrderListLoadable = Loadable({
  loader: () => import('../containers/container_orderList'),
  loading: Loading,
})

export const OrderList = (props) => <OrderListLoadable {...props} />;
