import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PublicationTypeList} from "../modules/publicationType_list/loaders";
import { CreatePublicationType } from "../modules/createPublicationType/loaders";
import { EditPublicationType } from "../modules/edit_publicationType/loaders";

export const RoutesPublicationType = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={ `${path}`}  component={ PublicationTypeList } />
      <Route path={`${path}/create`} component={ CreatePublicationType } />
      <Route path={`${path}/edit/:id`} component={ EditPublicationType } />
    </Switch>
  )
}
