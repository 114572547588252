import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditContractLoadable = Loadable({
  loader: () => import('../containers/container_editContract'),
  loading: Loading,
})

export const EditContract = props => <EditContractLoadable {...props}/>;