import { api } from "./interceptor";

export const getQuotations = () => {
  return api.get('/api/Quotation');
}

export const getQuotationsByFeature = (featureId) => {
  return api.get(`/api/Quotation?featureId=${featureId}`);
}

export const getQuotation = (id) => {
  return api.get(`/api/Quotation/${id}`);
}

export const getActiveQuotations = () => {
  return api.get(`/api/Quotation/active`);
}
export const getActiveQuotationsByFeature = (featureId) => {
  return api.get(`/api/Quotation/active?featureId=${featureId}`);
}
export const updateQuotation = (id, quotation) => {
  return api.put(`/api/Quotation/${id}`, quotation);
}

export const addQuotation = quotation => {
  return api.post(`/api/Quotation`, quotation);
}

export const removeQuotation = id => {
  return api.delete(`/api/Quotation/${id}`);
}
export const BASE_URL = `/api/Auth/`
