/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, NavLink, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import {
  AppBar,
  Drawer,
  Toolbar,
  List,
  Hidden,
  Typography,
} from '@material-ui/core/';

import { MainListItems } from './listItems';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { ReportsMenu, PageTitle } from 'common';
import { Isbn, Publisher, Control, User, Feature, Quotation } from 'pages';
import { useDispatch } from "react-redux";
import { Registration } from "./pages/registration";
import Login from "./modules/registration_autorization/containers/autorization";
import { logout } from "./common/userReducer";
import { Button } from "@material-ui/core";
import jwt_decode from 'jwt-decode';
import { PublisherAccount } from "./pages/publisherAccount";
import { PublicationType } from "./pages/publicationType";
import { Contract } from "./pages/contract";
import { Order } from "./pages/order";
import { Profile } from "./pages/profile";
import { IssuedIsbn } from "./pages/issuedIsbn";
import { getUser } from "./services/user_service";

const drawerWidth = 240;
let user = '';
const Notfound = () => {
  return <h1>Страница не найдена</h1>;
};

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    minWidth: drawerWidth,
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  content: {
    zIndex: 2,
  },
}));

const App = ({ container }) => {
  const classes = useStyles();

  const token = localStorage.getItem('token_isbn');

  let roleFromDecodedToken = '';

  const getUserInfo = async id => {
    try {
      const response = await getUser(id);
      setPublisherAccountName(response.data.publisherAccount.shortName);
    } catch (error) {
    }
  }

  if (token) {
    const decodedToken = jwt_decode(token);
    roleFromDecodedToken = decodedToken.role;
    user = decodedToken.email;
    getUserInfo(+decodedToken.sub);
  }

  const isAuth = (token !== null && token.length > 0) ? true : false;


  const [ sideBar, toggleSidebar ] = useState((isAuth && isAuth === true) ? true : false);
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const [ publisherAccountName, setPublisherAccountName ] = useState('нет данных');


  const dispatch = useDispatch();

  const handleDesktopDrawerToggle = () => {
    toggleSidebar(!sideBar);
  };

  function handleMobileDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <>
      <div className="page">
        <div className="header">
          <AppBar position="relative" className={ classes.appBar }>
            <Toolbar disableGutters className={ classes.toolbar }>
              { isAuth &&
                <Hidden smDown implementation="css">
                  <div className={ classNames( classes.toolbarIcon) }>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={ handleDesktopDrawerToggle }
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </Hidden>
              }
              { isAuth &&
                <Hidden mdUp implementation="css">
                  <div className={ classNames( classes.toolbarIcon) }>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={ handleMobileDrawerToggle }
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </Hidden>
              }

              <PageTitle />
              <div className="link_wrapper">
              { isAuth && window.innerWidth > 1900 && <div style={{marginRight: '40px'}}>БЕЛОРУССКОЕ АГЕНТСТВО МЕЖДУНАРОДНОЙ СТАНДАРТНОЙ КНИЖНОЙ НУМЕРАЦИИ ISBN. Онлайн-кабинет</div> }
              { isAuth && (roleFromDecodedToken === 'BIBLIOGRAPHER') && <div className="btn_reports"><ReportsMenu /></div> }
              { !isAuth && <div className="link_registration"><NavLink to="/registration">Регистрация</NavLink></div>  }
              { !isAuth && <div className="link_login"><NavLink to="/login">Войти </NavLink></div>  }
              { isAuth && (roleFromDecodedToken === 'PUBLISHER') && <div className="publisher_p"><Typography component='p' variant='p'>Издательство: {publisherAccountName}</Typography></div>}
              { isAuth && <div className="link_registration"><NavLink to="/profile">Профиль {user}</NavLink></div>}
              { isAuth && <div className="link_logout"><Button  onClick={() => dispatch(logout())}>Выход</Button></div> }
              </div>
            </Toolbar>
          </AppBar>
        </div>

          <div className="content">
            <Hidden smUp implementation="css">
              <Drawer
                container={ container }
                variant="temporary"
                open={ mobileOpen }
                onClose={ handleMobileDrawerToggle }
                classes={ {
                  paper: classes.drawerPaper,
                } }
                ModalProps={ {
                  keepMounted: true, // Better open performance on mobile.
                } }
              >
                <List disablePadding>
                  <MainListItems />
                </List>
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                variant="permanent"
                classes={ {
                  paper: classNames(
                    classes.drawerPaper,
                    !sideBar && classes.drawerPaperClose,
                  ),
                } }
                className='side-bar'
                open={ sideBar }
              >
                <List disablePadding>
                  <MainListItems />
                </List>
              </Drawer>
            </Hidden>
            <main id="content-wrapper" className={ `${ classes.content } main-bar` }>

              { !isAuth ?
              <Switch>
                <Route path="/registration" component={ Registration }/>
                <Route path ="/login" component={ Login }/>
                <Redirect to='/login'/>
              </Switch>

              :

                <Switch>
                  { (roleFromDecodedToken === 'BIBLIOGRAPHER') &&<Route path="/isbn" component={ Isbn }/> }
                  { (roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER')  &&<Route path="/publisher" component={ Publisher }/> }
                  { roleFromDecodedToken === 'BIBLIOGRAPHER' &&<Route path="/isbn-control" component={ Control } /> }
                  { (roleFromDecodedToken === 'ADMIN' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<Route path="/users" component={ User }/> }
                  { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<Route path="/feature" component={ Feature }/> }
                  { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<Route path="/quotation" component={ Quotation }/> }
                  { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<Route path="/publisherAccount" component={ PublisherAccount }/> }
                  { (roleFromDecodedToken === 'ADMIN' || roleFromDecodedToken === 'BIBLIOGRAPHER') && <Route path="/publicationType" component={ PublicationType }/>}
                  { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<Route path="/contract" component={ Contract }/> }
                  { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<Route path ="/order" component={ Order } /> }
                  { (roleFromDecodedToken === 'PUBLISHER') &&<Route path ="/isbn-issued" component={ IssuedIsbn } />}
                  <Route path="/profile" component={ Profile }/>
                  <Route render={ () => <Redirect to="/isbn"/> }/>
                  <Route path="*" component={ Notfound } />
                </Switch>
              }

            </main>
          </div>
      </div>
    </>
  );
};

App.propTypes = {
  container: PropTypes.object
};

export default App;
