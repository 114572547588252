import axios from "axios";
import { customHistory } from "../common/user";
import { BASE_URL } from "./user_service";

const api = axios.create({
  // baseURL: `${BASE_URL}`
});

function apiSetHeader(name, value) {
  if (value) {
    api.defaults.headers[name] = value
  }
}

const token = localStorage.getItem('token_isbn');

if (token) {
  apiSetHeader('Authorization', `Bearer ${token}`)
}

// Request interceptor
api.interceptors.request.use(config => {
  if (!config.headers['Authorization']) {
    customHistory.push('/login');
    document.location.reload(true);
  }

  if (token) {
    if (config.headers) {

      config.headers.token = token;
    }
  }
  return config;
}, error => {
  return Promise.reject(error)
})

//Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { api } ;

export default apiSetHeader ;

