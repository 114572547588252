import { api } from "./interceptor";

export const getOrders = () => {
  return api.get('/api/Order');
}

export const removeOrder = id => {
  return api.delete(`/api/Order/${ id }`);
}

export const getOrder = (id) => {
  return api.get(`/api/Order/${ id }`);
}

export const getFullOrder = (id) => {
  return api.get(`/api/Order/${ id }?withquotation=true&withfeature=true`)
}
export const updateOrder = (id, order) => {
  return api.put(`/api/Order/${id}`, order);
}

export const getOrderActiveByPublisherAccount = (id) => {
  return api.get(`/api/Order/active/${id}`)
}

export const addOrder = order => {
  return api.post(`/api/Order`, order);
}

export const getOrdersForPublishers = (idPublisher) => {
  return api.get(`/api/order?publisherAccountId=${idPublisher}`);
}

export const getFreeISBNForPublisher = (idPublisher) => {
  return api.get(`/api/isbn/free/isbn/${idPublisher}`);
}
