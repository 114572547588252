import * as TYPES from './types';
import { Map } from 'immutable';

const defaultState = Map({
  selectedRow: null,
  rowsToDisplay: []
});

export const orderInformation = (state = defaultState, action) => {
  switch (action.type) {
    case TYPES.SET_SELECTED_ROW: {
      return state.setIn(['selectedRow'], action.payload.rowId);
    }

    case TYPES.SET_ROWS_TO_DISPLAY: {
      return state.setIn(['rowsToDisplay'], action.payload.rowsData);
    }

    default:
      return state;
  }
};
