import React, {useState} from 'react';
import '../autorization.module.scss'
import { entrance} from '../../../common/user';
import Input from "../../Input/containers/input";
import { useDispatch } from "react-redux";
import styles from "../autorization.module.scss";

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()
  return (
    <div className={ styles.authorization }>
        <div className= { styles.authorization_header }>Авторизация</div>
        <Input value={login} setValue={setLogin} type="text" placeholder="Введите login..."/>
        <Input value={password} setValue={setPassword} type="password" placeholder="Введите пароль..."/>
        <button className={ styles.authorization_btn } onClick={(handleLogin) => dispatch(entrance(login, password))}>Войти</button>
    </div>
  );
};

export default Login;
