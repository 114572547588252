import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const PublisherAccountListLoadable = Loadable({
  loader:() => import('../containers/container_publisherAccountList'),
  loading: Loading,
})

export const PublisherAccountList = (props) => <PublisherAccountListLoadable {...props} />;
