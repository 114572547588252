import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { OrderList } from "../modules/order_list/loaders";
import { CreateOrder } from "../modules/create_order/loaders";
import { EditOrder } from "../modules/edit_order/loaders";

export const RouteOrder = () => {
  let {path} = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ OrderList }/>
      <Route path={`${path}/create`} component={ CreateOrder }/>
      <Route path={`${path}/edit/:id`} component={ EditOrder }/>
    </Switch>
  )
}
