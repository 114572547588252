import axios from 'axios';

export const addPublisher = publisher => {
  return axios.post('/api/publishers', publisher);
};

export const getPublisher = (id, withIsbns) => {
  return axios.get(`/api/publishers/${ id }?withIsbns=${ withIsbns }`);
};

export const getPublishers = () => {
    return axios.get('/api/publishers');
};

export const updatePublisher = (id, publisher) => {
  return axios.put(`/api/publishers/${ id }`, publisher);
};

export const removePublisher = id => {
  return axios.delete(`/api/publishers/${ id }`);
};


