import axios from "axios";
import { api } from "./interceptor";

export const getPublisherAccountById = publisherAccountId => {
  return axios.get(`/api/PublisherAccount/${publisherAccountId}`)
}
export const getPublisherAccounts = () => {
  return axios.get('/api/PublisherAccount');
}

export const getContractOrder = contractId => {
  return api.get(`/api/Contract/order/${contractId}`);
}

export const addPublisherAccount = publisherAccount => {
  return axios.post('/api/PublisherAccount', publisherAccount);
};

export const removePublisherAccount = id => {
  return axios.delete(`/api/PublisherAccount/${id}`);
}

export const updatePublisherAccount = (id, publisherAccount) => {
  return axios.put(`/api/PublisherAccount/${id}`, publisherAccount);
}

export const getPublisherAccountsForPublisher = (publisherAccountId) => {
  return axios.get(`/api/PublisherAccount?publisherAccountId=${publisherAccountId}`)
}
