import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import  { CreateFeature } from "../modules/create_feature/loaders";
import { FeatureList } from "../modules/feature_list/loaders";
import { EditFeature } from "../modules/edit_feature/loaders";

export const RouteFeature = () => {
  let {path} = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ FeatureList} />
      <Route path={`${path}/create`} component={ CreateFeature }/>
      <Route path={`${path}/edit/:id`} component={EditFeature}/>
    </Switch>
  );
}
