import axios from 'axios';
import apiSetHeader, { api } from "./interceptor";
import jwt_decode from "jwt-decode";

// export const addUser = user => {
//   return axios.post(`/api/Auth/registration`, user);
// };

export const getUser = (id) => {
  return api.get(`/api/Account/${ id }`);
}

export const getUsersLimit = (accountId) => {
  return axios.get(`/api/Account/accounts/${accountId}`);
}

export const getUsersLimitWithUser = (accountId, id) => {
  return axios.get(`/api/Account/accounts/${accountId}?id=${id}`);
}

export const getUsers = () => {
  return api.get('api/Account');
}

export const getPublisherUsers = (id) => {
  return api.get(`/api/Account/publisher/${id}`);
}

// export const updateUser = (id, user) => {
//   return axios.put(`${api}${ id }`, user);
// }

// export const removeUser = id => {
//   return axios.delete(`${api}${ id }`);
// };

export const resetPassword = (id, user) => {
  return api.put(`${BASE_URL}resetPassword/${id}`, user);
}
export const BASE_URL = `/api/Auth/`
