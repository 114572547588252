import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CreateUser} from "../modules/create_user/loaders";
import { UserList } from "../modules/user_list/loaders";
import { EditUser } from "../modules/edit_user/loaders";
import { ChangePassword } from "../modules/changePass_modal/loaders";

export const RoutesUser = () => {
  let {path} = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ UserList }/>
      <Route path={`${path}/create`} component={ CreateUser }/>
      <Route path={`${path}/edit/:id`} component={ EditUser }/>
      <Route path={`${path}/changePassword/:id`} component={ ChangePassword }/>
    </Switch>
  );
}
