import { Map } from 'immutable';
import { LOGOUT, SET_USER } from "../modules/user_list/types";
import React from "react";
import { createBrowserHistory } from "history";

export const customHistory = createBrowserHistory();
const defaultState = Map(
  {
    currentUser: {},
    isAuth: false
  }
)

export default function user(state = defaultState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser:action.payload,
        isAuth: true
      }
    case LOGOUT:
      localStorage.removeItem('token_isbn')
      customHistory.push('/login');
      document.location.reload(true);
      return {
        ...state,
        currentUser: {},
        isAuth: false
      }
    default:
      return state
  }
}

export const setUser = user => ({type: SET_USER, payload: user});
export const logout = () => ({type: LOGOUT})
