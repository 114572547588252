import axios from "axios";
import { api } from "./interceptor";
import jwt_decode from "jwt-decode";

export const getContracts = () => {
  return api.get('/api/Contract/all');
}

export const createContract = contract => {
  return api.post(`/api/Contract`, contract);
}

export const getContract = (id) => {
  return api.get(`/api/Contract/${id}`);
}

export const updateContract = (id, contract) => {
  return api.put(`/api/Contract/${id}`, contract);
}

export const removeContract = id => {
  return api.delete(`/api/Contract/${id}`);
}

let roleFromDecodedToken = '';
const token = localStorage.getItem('token_isbn');

if (token) {
  const decodedToken = jwt_decode(token);
  roleFromDecodedToken = decodedToken.role;
  const publisherAccountId = decodedToken.publisheraccount;
  if (roleFromDecodedToken === 'PUBLISHER' &&  publisherAccountId) {

  }
}
export const getContractsForPublishers = (publisherAccountId) => {
  return api.get(`/api/contract/all?publisherAccountId=${publisherAccountId}`);
}
