import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditPublisherAccountLoadable = Loadable({
  loader: () => import('../containers/container_editPublisherAccount'),
  loading: Loading
});

export const EditPublisherAccount = props => <EditPublisherAccountLoadable {...props}/>;
