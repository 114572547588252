import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Registration } from "../modules/registration_autorization/loaders/registration";
import { CreatePublisherAccount } from "../modules/create_publisherAccount/loaders";


export const RoutesRegistration = () => {
  return (
    <Switch>
      <Route path="/registration" component={ Registration }/>
      <Route path="/api/PublisherAccount/create" component={ CreatePublisherAccount }/>
    </Switch>
  )
}
