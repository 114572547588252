import React from 'react';
import PropTypes from 'prop-types';

import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { makeStyles } from '@material-ui/styles';
import { TextField, Paper, MenuItem } from '@material-ui/core/';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={ {
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      } }
      { ...other }
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={ isHighlighted } component="div">
      <div>
        {parts.map(part => (
          <span key={ part.text } style={ { fontWeight: part.highlight ? 500 : 400 } }>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const AutoSuggest = function AutoSuggest({ onChange, value, onSuggestionsFetchRequested, label, required }) {
  const classes = useStyles();

  const [ stateSuggestions, setSuggestions ] = React.useState([]);

  const handleSuggestionsFetchRequested = async ({ value }) => {
    const response = await onSuggestionsFetchRequested(value);
    setSuggestions(getSuggestions(value, response));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue }) => {
    onChange(newValue);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={ classes.root }>
      <Autosuggest
        { ...autosuggestProps }
        inputProps={ {
          classes,
          label: label,
          value: value,
          onChange: handleChange,
          required: required
        } }
        theme={ {
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        } }
        renderSuggestionsContainer={ options => (
          <Paper { ...options.containerProps } square>
            { options.children }
          </Paper>
        ) }
      />
    </div>
  );
};

AutoSuggest.propTypes = {
  suggestions: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onSuggestionsFetchRequested: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default AutoSuggest;
