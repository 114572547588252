import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const CreatePublisherAccountLoader = Loadable({
  loader: () => import('../containers/container_publisherAccount'),
  loading: Loading
});

export const CreatePublisherAccount = (props) => <CreatePublisherAccountLoader {...props} />