import React from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox, FormControlLabel, TextField, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

export const PublisherForm = ({ publisher, handleChange, handleSubmit, submitButtonText, loading, onCancel }) => {
  const classes = useStyles();

  return (
    <Paper style={{ padding: 16 }}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="publisher"
            label="Издательство"
            className={classes.item}
            value={publisher.name || ''}
            onChange={handleChange('name')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="licenseNumber"
            label="№ св-ва о гос. регистрации издателя"
            className={classes.item}
            value={publisher.licenseNumber || ''}
            onChange={handleChange('licenseNumber')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="type"
            label="Тип"
            className={classes.item}
            value={publisher.type || ''}
            onChange={handleChange('type')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="specialization"
            label="Специализация"
            className={classes.item}
            value={publisher.specialization || ''}
            onChange={handleChange('specialization')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="postcode"
            label="Индекс"
            className={classes.item}
            value={publisher.postcode || ''}
            onChange={handleChange('postcode')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="town"
            label="Город"
            className={classes.item}
            value={publisher.town || ''}
            onChange={handleChange('town')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="street"
            label="Улица"
            className={classes.item}
            value={publisher.street || ''}
            onChange={handleChange('street')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="office"
            label="Офис"
            className={classes.item}
            value={publisher.office || ''}
            onChange={handleChange('office')}
            fullWidth
          /></Grid>

        <Grid item xs={6}>
          <TextField
            id="phone"
            label="Телефон"
            className={classes.item}
            type="tel"
            value={publisher.phone || ''}
            onChange={handleChange('phone')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="fax"
            label="Факс"
            className={classes.item}
            value={publisher.fax || ''}
            onChange={handleChange('fax')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="email"
            label="Email"
            className={classes.item}
            type="email"
            value={publisher.email || ''}
            onChange={handleChange('email')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="site"
            label="Сайт"
            className={classes.item}
            value={publisher.site || ''}
            onChange={handleChange('site')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="director"
            label="Директор"
            className={classes.item}
            value={publisher.director || ''}
            onChange={handleChange('director')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="notes"
            label="Примечания"
            className={classes.item}
            value={publisher.notes || ''}
            onChange={handleChange('notes')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={publisher.liquidated}
                onChange={handleChange('liquidated')}
              />
            }
            label="Ликвидировано"
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={loading}
        onClick={handleSubmit}
      >
        {submitButtonText}
      </Button>

      {
        onCancel && <Button
          type="submit"
          variant="contained"
          className={classes.button}
          disabled={loading}
          onClick={onCancel}
        >
          Отмена
      </Button>
      }
    </Paper>
  );
};

PublisherForm.propTypes = {
  publisher: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};
