import * as TYPES from './types';
import { Map } from 'immutable';

const defaultState = Map({
  showMasterLoading: false,
});

export const master = (state = defaultState, action) => {
  switch (action.type) {
      case TYPES.ACTION_SHOW_MASTER_LOADING: {
        return state.setIn([ 'showMasterLoading' ], true);
      }

      case TYPES.ACTION_HIDE_MASTER_LOADING: {
        return state.setIn([ 'showMasterLoading' ], false);
      }

      default:
          return state;
  }
};
