import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Registration } from "../modules/registration_autorization/loaders/registration";
import { Profile } from "../modules/profile/loaders/profile";
// import { ChangePassword } from "../modules/changePass_modal/loaders";

export const RoutesProfile = () => {
  return (
    <Switch>
      <Route path="/profile" component={ Profile } />
      {/*<Route  path={'/profile/changePass/:id'} component={ ChangePassword }/>*/}
    </Switch>
  )
}
