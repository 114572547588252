import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const ContractListLoadable = Loadable({
  loader:() => import('../containers/container_contractList'),
  loading: Loading,
})

export const ContractList = (props) => <ContractListLoadable {...props} />;
