import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const ChangePasswordLoadable = Loadable({
  loader: () => import('../containers/changePass'),
  loading: Loading,
});

export const ChangePassword = props => <ChangePasswordLoadable {...props} />;