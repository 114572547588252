import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditIssuedIsbnLoadable = Loadable({
  loader: () => import('../containers/container_editIssuedIsbn'),
  loading: Loading,
});

export const EditIssuedIsbn = props => <EditIssuedIsbnLoadable { ...props } />;
