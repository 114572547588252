import React, { useEffect, useState } from 'react';

import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core/';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';

import { saveAs } from 'file-saver';
import moment from 'moment';
import ISBNStatisticsButton from "./statistics-btn";

const useStyles = makeStyles(() => ({
  reports: {
    marginLeft: 'auto'
  },
}));

export const ReportsMenu = () => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [apiUrlForDateRange, setApiUrlForDateRange] = useState('');
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    fromError: false,
    toError: false,
  });

  useEffect(() => {
    if (apiUrl !== '') {
      saveAs(apiUrl);
    }
  }, [apiUrl]);


  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleByRange = (event, baseApiUrl) => {
    setOpen(true);
    setDialogTitle(event.target.innerText);
    setApiUrlForDateRange(baseApiUrl);
  };

  function handleCloseDialog() {
    setOpen(false);
  }

  const handleChangeDate = (name, value) => {
    setDate({ ...date, [name]: value.format('YYYY-MM-DD'), fromError: false, toError: false });
  };

  function handleSubmitDialog() {
    if (!date.from || !date.to) {
      return setDate({ ...date, fromError: date.from ? false : true, toError: date.to ? false : true });
    }

    setAnchorEl(null);
    setOpen(false);

    setApiUrl(`${apiUrlForDateRange}?from=${date.from}&to=${date.to}`);
  }

  function getDocxIsbn() {
    setAnchorEl(null);
    setApiUrl('/api/docx/isbn');
  }

  function getDocxPublishers() {
    setAnchorEl(null);
    setApiUrl('/api/docx/publishers');
  }

  function getDocxLicenses() {
    setAnchorEl(null);
    setApiUrl('/api/docx/licenses');
  }

  function getMacsv() {
    setAnchorEl(null);
    setApiUrl('/api/docx/macsv');
  }

  function getMacsvtr() {
    setAnchorEl(null);
    setApiUrl('/api/docx/macsvtr');
  }

  return (
    <div className={classes.reports}>
      <Button
        aria-owns={anchorEl ? 'reports-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        type="button"
      >
        Отчеты
      </Button>
      <Menu id="reports-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={getDocxIsbn}>Список выданных префиксов ISBN</MenuItem>
        <MenuItem onClick={getDocxPublishers}>Список издательств, получивших префиксы ISBN</MenuItem>
        <MenuItem onClick={(event) => handleByRange(event, '/api/docx/publishers/period')}>Список издательств, впервые получивших префикс ISBN за период</MenuItem>
        <MenuItem onClick={getDocxLicenses}>Список издательств с лицензиями</MenuItem>
        <MenuItem onClick={getMacsv}>Список издательств для Международного Агенства ISBN в CSV-формате</MenuItem>
        <MenuItem onClick={getMacsvtr}>Список издательств для Международного Агенства ISBN в CSV-формате на английском языке</MenuItem>
        <MenuItem onClick={(event) => handleByRange(event, '/api/docx/errorChecks')}>Список ошибочных номеров за период</MenuItem>
        <ISBNStatisticsButton></ISBNStatisticsButton>
        <MenuItem onClick={(event) => handleByRange(event, '/api/docx/issuedPrefix')}>Список префикс издателей, выданных за период</MenuItem>
        <MenuItem onClick={(event) => handleByRange(event, '/api/docx/calculatedIsbn')}>Перечень издателей и количество рассчитанных им номеров за период</MenuItem>
      </Menu>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="С"
                openTo="year"
                value={date.from}
                onChange={(value) => handleChangeDate('from', value)}
                format={'DD/MM/YYYY'}
                fullWidth
                error={date.fromError}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="По"
                openTo="year"
                value={date.to}
                onChange={(value) => handleChangeDate('to', value)}
                format={'DD/MM/YYYY'}
                fullWidth
                error={date.toError}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCloseDialog} color="primary">
            Отмена
          </Button>
          <Button type="button" onClick={handleSubmitDialog} color="primary">
            Обработать
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
};