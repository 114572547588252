import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { ContractList } from "../modules/contract_list/loaders";
import { CreateContract } from "../modules/create_contract/loaders";
import { EditContract } from "../modules/edit_contract/loaders";

export const RoutesContract = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ ContractList }/>
      <Route path={`${path}/create`} component={ CreateContract }/>
      <Route path={`${path}/edit/:id`} component= { EditContract }/>
    </Switch>
  )
}
