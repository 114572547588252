import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditPublisherLoadable = Loadable({
    loader: () => import('../containers/container_editPublisher'),
    loading: Loading,
});

export const EditPublisher = props => <EditPublisherLoadable { ...props } />;
