import React, { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    IconButton,
} from '@material-ui/core/';
import ClearIcon from "@material-ui/icons/Clear";

import { DatePicker } from '@material-ui/pickers';

import { saveAs } from 'file-saver';

export default function ISBNStatisticsButton() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <MenuItem onClick={handleClickOpen}>
                Статистика ISBN
            </MenuItem>
            <ISBNStatisticsDialog open={open} onClose={handleClose} />
        </div>
    );
}


function ISBNStatisticsDialog(props) {
    const { onClose, open } = props;
    const [date, setDate] = useState(null);
    const [dateError] = useState(null);


    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        let baseUrl = '/api/docx/statistics';
        if (date) { baseUrl += `?onDate=${date}` }
        saveAs(baseUrl);
    };

    const handleChangeDate = (value) => {
        if (value) {
            setDate(value.format('YYYY-MM-DD'));
        } else {
            setDate(value);
        }
    };

    const handleClear = (event) => {
        event.stopPropagation();
        setDate(null);
    };

    return (
        <Dialog fullWidth={true} maxWidth={'xs'} onClose={handleClose} aria-labelledby="get statistics report dialog" open={open}>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <DialogTitle id="statistic-dialog">Статистика ISBN</DialogTitle>
                <DialogContent>
                    <DatePicker
                        label="Статистика на дату"
                        value={date}
                        clearable
                        onChange={(value) => handleChangeDate(value)}
                        format={'DD/MM/YYYY'}
                        fullWidth
                        error={dateError}
                        InputProps={!!date && {
                            endAdornment: (
                                <IconButton type="button" onClick={(e) => handleClear(e)}>
                                    <ClearIcon />
                                </IconButton>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Обработать
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}