import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const PublisherListLoadable = Loadable({
    loader: () => import('../containers/container_publisherList'),
    loading: Loading,
});

export const PublisherList = (props) => <PublisherListLoadable { ...props } />;
