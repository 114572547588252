import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditUserLoadable = Loadable({
  loader: () => import('../containers/container_editUser'),
  loading: Loading,
});

export const EditUser = props => <EditUserLoadable { ...props} />;
