import React, { memo, useContext } from 'react';
import Typography from '@material-ui/core/Typography';

import TitleContext from 'context/title_context';

export const PageTitle = memo(function PageTitle() {

  const { title } = useContext(TitleContext);

  return (
    <Typography component="h1" variant="h6" color="inherit" noWrap>
      { title }
    </Typography>
  );
});
