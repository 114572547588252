import axios from 'axios';

export const isbnControl = ({ isbntext, format }) => {
  return axios.get('/api/ISBNCheck/isbn', {
    params: { isbntext, format }
  });
};

export const isbnControlSaveError = (data) => {
  return axios.post('/api/ISBNCheck', data);
};

export const isbnControlErrorsList = ({ from, to }) => {
  return axios.get('/api/ISBNCheck', {
    params: { from, to }
  });
};
