import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const ProfileLoadable = Loadable({
  loader: () => import('../containers/container_profile'),
  loading: Loading,
});

export const Profile = (props) => <ProfileLoadable {...props}/>;
