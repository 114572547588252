import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { QuotationList } from "../modules/quotation_list/loaders";
import { CreateQuotation } from "../modules/create_quotation/loaders";
import { EditQuotation } from "../modules/edit_quotation/loaders";


export const RoutesQuotation = () => {
  let {path} = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ QuotationList }/>
      <Route path={`${path}/create`} component={ CreateQuotation }/>
      <Route path={`${path}/edit/:id`} component={EditQuotation}/>
    </Switch>
  )
}
