import { combineReducers } from 'redux-immutable';
import { all } from 'redux-saga/effects';

import { master } from './master';
import { isbn } from './isbn_list';
import { publisher } from './publisher_list';
import user from "../common/userReducer";
import { publisherAccount } from "./pablisherAccount_list";
import { feature } from "./feature_list";
import { publicationType } from "./publicationType_list";
import { contract } from "./contract_list";
import { order } from "./order_list";
import { quotation } from "./quotation_list";
import { orderInformation } from "./orderInformation_list";
import { issuedIsbn } from "./issued_isbn_list/reducers";

const rootReducer = combineReducers({
  master,
  isbn,
  publisher,
  user,
  publisherAccount,
  feature,
  publicationType,
  contract,
  order,
  quotation,
  orderInformation,
  issuedIsbn,
});

const rootSaga = function* () {
  yield all([
  ]);
};

export { rootReducer, rootSaga }; // было реализованоо изначально
// export const store = createStore(rootSaga, composeWithDevTools(applyMiddleware(thunk)));
