import React from 'react';
import PropTypes from 'prop-types';

import styles from './common.module.scss';

export const Overlay = ({ children }) => (
  <div className={ styles.overlay }>
    { children }
  </div>
);

Overlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};
