import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const QuotationListLoadable = Loadable( {
  loader: () => import('../containers/container_quotationList'),
  loading: Loading,
})

export const QuotationList = (props) => <QuotationListLoadable {...props}/>;
