import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const IsbnErrorsLoadable = Loadable({
    loader: () => import('../containers/container_isbn_errors'),
    loading: Loading,
});

export const IsbnErrors = () => <IsbnErrorsLoadable />;
