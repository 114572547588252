import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const IssuedIsbnListLoader = Loadable({
  loader: () => import('../containers/container_issued_isbnList'),
  loading: Loading,
});

export const IssuedIsbnList = (props) => <IssuedIsbnListLoader { ...props }/>;
