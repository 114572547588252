import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { IsbnList } from 'modules/isbn_list/loaders';
import { CreateIsbn } from 'modules/create_isbn/loaders';
import { EditIsbn } from 'modules/edit_isbn/loaders';
import { useRouteMatch } from "react-router-dom";

export const RoutesIsbn = () => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={IsbnList} />
      <Route path={`${path}/create`} component={CreateIsbn} />
      <Route path={`${path}/edit/:id`} component={EditIsbn} />
    </Switch>
  )
}
