import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const UserListLoadable = Loadable( {
  loader: () => import('../containers/container_userList'),
  loading: Loading,
});

export const UserList = (props) => <UserListLoadable {...props} />;
