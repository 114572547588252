import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const CreatePublicationTypeLoader = Loadable({
  loader: () => import('../containers/container_createPublicationType'),
  loading: Loading,
});

export const CreatePublicationType = (props) => <CreatePublicationTypeLoader {...props} />;
