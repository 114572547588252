import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const IsbnListLoader = Loadable({
    loader: () => import('../containers/container_isbnList'),
    loading: Loading,
});

export const IsbnList = (props) => <IsbnListLoader { ...props } />;
