import axios, { CancelToken } from 'axios';
import { api } from "./interceptor";

export const addIsbn = isbn => {
  return axios.post('/api/isbn', isbn);
};

export const getIsbn = id => {
  return axios.get(`/api/isbn/${ id }`);
};

export const getIsbns = (withPublisher, withIsbnCol) => {
  return axios.get('/api/isbn', {
    params: { withPublisher, withIsbnKol: withIsbnCol }
  });
};

export const updateIsbn = (id, isbn) => {
  return axios.put(`/api/isbn/${ id }`, isbn);
};

export const removeIsbn = id => {
  return axios.delete(`/api/isbn/${ id }`);
};

export const addKol = (kol) => {
  return api.post('/api/isbnKol', kol);
};

export const deleteKol = (kolId) => {
  return api.delete(`/api/isbnKol/${ kolId }`);
};

export const freeIsbn = (value) => {
  const source = CancelToken.source();
  const request = axios.get(`/api/isbn/free?start=${ value }`);
  request[ 'cancel' ] = () => source.cancel();
  return request;
};
