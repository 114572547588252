/* eslint-disable */
import React, { Component } from 'react';

import Select from 'react-select';
import { AutoSizer, List } from 'react-virtualized';

import { Typography, TextField, Paper, MenuItem } from '@material-ui/core/';


const styles = {
  input: {
    display: 'flex',
    height: 19
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  singleValue: {
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    height: 300
  }
};

const NoOptionsMessage = (props) => {
  return (
    <Typography
      color="textSecondary"
      { ...props.innerProps }
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={ inputRef } { ...props } />;
};

const Control = (props) => {
  return (
    <TextField
      fullWidth
      id="value"
      name="value"
      InputProps={ {
        inputComponent,
        inputProps: {
          style: styles.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        }
      } }
      InputLabelProps={ {
        shrink: true
      } }
      { ...props.selectProps.textFieldProps }
    />
  );
};

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={ props.innerRef }
      selected={ props.isFocused }
      component="div"
      style={ {
        fontWeight: props.isSelected ? 500 : 400
      } }
      { ...props.innerProps }
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = (props) => {
  return (
    <Typography
      color="textSecondary"
      { ...props.innerProps }
    >
      {props.children}
    </Typography>
  );
};

const SingleValue = (props) => {
  return (
    <Typography style={ styles.singleValue } { ...props.innerProps }>
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props) => {
  return <div style={ styles.valueContainer }>{props.children}</div>;
};

const Menu = (props) => {
  return (
    <Paper square style={ styles.paper } { ...props.innerProps }>
      {props.children}
    </Paper>
  );
};

const MenuList = props => {
  const rows = props.children;
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div key={ key } style={ style }>{rows[ index ]}</div>
  );
  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={ width }
          height={ 300 }
          rowCount={ rows.length ? rows.length : 0 }
          rowHeight={ 50 }
          rowRenderer={ rowRenderer }
        />
      )}
    </AutoSizer>
  );
};

const components = {
  Control,
  Menu,
  MenuList,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function VirtualizedSelect({ value, options, isLoading, placeholder, onFocus, onChange, label, disabled, defaultValue }) {

  return (
    <div style={ { width: '100%' } }>
      {/*, margin: '20px'*/}
      <Select
        textFieldProps={ {
          label: label,
          InputLabelProps: {
            shrink: true,
          },
        } }
        placeholder={ false }
        components={ components }
        value={ value }
        options={ options }
        isLoading={ isLoading }
        onFocus={ onFocus }
        onChange={ onChange }
        disabled={ true }
        defaultValue={defaultValue}
      />
    </div>

  );
}

export default VirtualizedSelect;
