import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from "react-router-dom";
import { IssuedIsbnList } from "../modules/issued_isbn_list/loaders";
import { EditIssuedIsbn } from "../modules/edit_issued_isbn/loaders";


export const RoutesIssuedIsbn = () => {
  let { path , url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ IssuedIsbnList } />
      <Route path={`${path}/edit/:id`} component={ EditIssuedIsbn } />
    </Switch>
  )
}
