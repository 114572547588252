import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const CreateOrderLoader = Loadable({
  loader: () => import('../container/container_createOrder'),
  loading: Loading,
});

export const CreateOrder = (props) => <CreateOrderLoader {...props}/>;
