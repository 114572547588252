import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const CreateUserLoader = Loadable( {
  loader: () => import('../containers/container_createUser'),
  loading: Loading,
});

export const CreateUser = (props) => <CreateUserLoader {...props} />;
