import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { IsbnControl } from 'modules/control/loaders';
import { IsbnErrors } from 'modules/isbn_errors/loaders';

export const RoutesControl = () => (
  <Switch>
    <Route exact path="/isbn-control" component={ IsbnControl } />
    <Route exact path="/isbn-control/errors" component={ IsbnErrors } />
  </Switch>
);
