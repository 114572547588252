import { api } from "./interceptor";

export const getFeatures = () => {
  return api.get('/api/Feature');
}

export const addFeature = feature => {
  return api.post(`/api/Feature`, feature);
}

export const getFeature = (id) => {
  return api.get(`/api/Feature/${id}`);
}

export const updateFeature = (id, feature) => {
  return api.put(`/api/Feature/${id}`, feature)
}

export const removeFeature = id =>  {
  return api.delete(`/api/Feature/${id}`);
}
export const BASE_URL = `/api/Auth/`
