import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const EditIsbnLoadable = Loadable({
    loader: () => import('../containers/container_editIsbn'),
    loading: Loading,
});

export const EditIsbn = props => <EditIsbnLoadable { ...props } />;
