import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { CreatePublisherAccount } from "../modules/create_publisherAccount/loaders";

import { PublisherAccountList } from "../modules/pablisherAccount_list/loaders";
import { EditPublisherAccount } from "../modules/edit_publisherAccount/loaders";

export const RoutesPublisherAccount = () => {
  let { path} = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ PublisherAccountList }/>
      <Route path={`${path}/create`} component={ CreatePublisherAccount }/>
      <Route path={`${path}/edit/:id`} component={ EditPublisherAccount }/>
    </Switch>
  )
}
