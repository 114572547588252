import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import jwt_decode from "jwt-decode";
import { Button, Paper } from "@material-ui/core";
import { ClickAwayListener, Grow, MenuList, Popper } from "@mui/material";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function ListItemLink({ icon, primary, to }) {
  const { location } = useHistory();

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} selected={to === location.pathname} >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.node,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};


export const MainListItems = () => {
  let roleFromDecodedToken = '';
  const token = localStorage.getItem('token_isbn');

  if (token) {
    const decodedToken = jwt_decode(token);
    roleFromDecodedToken = decodedToken.role;
      console.log('roleFromToken', roleFromDecodedToken)
  }

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}

      >
        Справочники
      </Button>
      { (roleFromDecodedToken === 'ADMIN' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER')  && <ListItemLink to="/users" primary="Пользователи"/> }
      { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<ListItemLink to="/contract" primary="Договора"/> }
      { (roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<ListItemLink to="/order" primary="Заказы" /> }
      { (roleFromDecodedToken === 'BIBLIOGRAPHER') &&<ListItemLink to="/isbn" primary="Расчет ISBN" /> }
      { (roleFromDecodedToken === 'BIBLIOGRAPHER') &&<ListItemLink to="/isbn-control" primary="Проверка ISBN" /> }
      { (roleFromDecodedToken === 'BIBLIOGRAPHER') &&<ListItemLink to="/isbn-control/errors" primary="Ошибки проверки ISBN" /> }
      { (roleFromDecodedToken === 'PUBLISHER') &&<ListItemLink to="/isbn-issued" primary="Выданные ISBN" /> }

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                 {(roleFromDecodedToken === 'BIBLIOGRAPHER') &&<ListItemLink to="/publisher" onClick={handleClose} primary="Справочник издателей" />}
                 {(roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<ListItemLink to="/feature" onClick={handleClose} primary="Справочник услуг"/>}
                 {(roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<ListItemLink to="/quotation" onClick={handleClose} primary="Справочник цен"/>}
                 {(roleFromDecodedToken === 'ACCOUNTENT' || roleFromDecodedToken === 'BIBLIOGRAPHER' || roleFromDecodedToken === 'PUBLISHER') &&<ListItemLink to="/publisherAccount" onClick={handleClose} primary="Справочник издательств"/>}
                 {(roleFromDecodedToken === 'ADMIN' || roleFromDecodedToken === 'BIBLIOGRAPHER') &&<ListItemLink to="/publicationType" onClick={handleClose} primary="Справочник видов изданий"/>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>

  );
};
