import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'common';

const EditPublicationTypeLoadable = Loadable({
  loader: () => import('../containers/container_publicationType'),
  loading: Loading,
});

export const EditPublicationType = props => <EditPublicationTypeLoadable {...props}/>;
