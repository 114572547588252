import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import styles from './common.module.scss';

export const Loader = (props) => (
  <div className={ styles.loader }>
    <CircularProgress size={ props.size } />
  </div>
);

Loader.propTypes = {
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 50
};
