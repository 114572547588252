import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const CreatePublisherLoader = Loadable({
    loader: () => import('../containers/container_createPublisher'),
    loading: Loading,
});

export const CreatePublisher = (props) => <CreatePublisherLoader { ...props } />;
