import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const CreateIsbnLoader = Loadable({
    loader: () => import('../containers/container_createIsbn'),
    loading: Loading,
});

export const CreateIsbn = (props) => <CreateIsbnLoader { ...props } />;
