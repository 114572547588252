import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from 'common';

const IsbnControlLoadable = Loadable({
    loader: () => import('../containers/container_isbn'),
    loading: Loading,
});

export const IsbnControl = () => <IsbnControlLoadable />;
