import * as TYPES from './types';
import { Map } from 'immutable';

const defaultState = Map({
  selectedRow: null
});

export const isbn = (state = defaultState, action) => {
  switch (action.type) {
      case TYPES.SET_SELECTED_ROW: {
        return state.setIn([ 'selectedRow' ], action.payload.rowId);
      }

      default:
          return state;
  }
};
